<template>
  <div class="atom-tenant-member-badge fill-height d-flex align-center">
    <atom-button
      :small="!small"
      :x-small="small"
      fab
      style="pointer-events: none"
      :dark="dark"
      :color="dark ? 'cs-soft-white' : 'cs-green-forest'">
      <div class="text-no-wrap" :class="dark ? 'text--cs-green-forest': 'white--text'">
        {{ value.initials }}
      </div>
    </atom-button>
    <div class="d-flex flex-column" :class="small ? 'ml-2' : 'ml-4'">
      <div class="text-no-wrap" :class="!small ? 'subtitle-1': ''">{{ value.displayName }}</div>
      <div class="body-2 text-no-wrap" v-if="formattedCreatedTimestamp !== undefined && showCreatedTimestamp">
        {{ formattedCreatedTimestamp }}
      </div>
      <div class="body-2 text-no-wrap" v-if="showRole">
        <atom-tenant-role-chip :key="role" v-for="role of value.roles" :value="role" text></atom-tenant-role-chip>
      </div>
      <div class="body-2 text-no-wrap" v-if="showEmail && email">
        {{ email }}
      </div>
    </div>
    <template v-if="showRoleChip">
      <v-spacer></v-spacer>
      <div class="d-flex flex-column">
        <atom-tenant-role-chip :key="role" v-for="role of value.roles" :value="role"></atom-tenant-role-chip>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.atom-tenant-member-badge {
}
</style>

<script lang="ts">
import type { PropType } from 'vue'
import { computed, defineComponent, toRefs } from 'vue'
import { format } from 'date-fns'
import type { PublicTenantMember, TenantMember } from 'app-model.carbon-saver'
import AtomButton from '../../../../components/atoms/AtomButton.vue'
import { useI18n } from '../../../../use/i18n'
import { tl } from '../../../../plugins/i18next'
import AtomTenantRoleChip from './AtomTenantRoleChip.vue'

export default defineComponent({
  name: 'AtomTenantMemberBadge',
  components: { AtomTenantRoleChip, AtomButton },
  props: {
    value: {
      type: Object as PropType<TenantMember | PublicTenantMember>,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    showCreatedTimestamp: {
      type: Boolean,
      default: false
    },
    showRole: {
      type: Boolean,
      default: false
    },
    showRoleChip: {
      type: Boolean,
      default: false
    },
    showEmail: {
      type: Boolean,
      default: false
    }
  },
  setup: (props) => {
    const { currentDateLocale } = useI18n()

    const { value } = toRefs(props)
    const formattedCreatedTimestamp = computed<string | undefined>(() => {
      if (value.value.createdTimestamp === undefined) return undefined

      const date = format(value.value.createdTimestamp, 'P', { locale: currentDateLocale.value })

      return tl('atom-tenant-member-badge.created-date', {
        defaultValue: 'invité le {{date}}',
        date
      }).replaceAll('&#x2F;', '/')
    })

    const email = computed(() => {
      if ((value.value as TenantMember).email) {
        return (value.value as TenantMember).email
      }
      return undefined
    })

    return {
      formattedCreatedTimestamp,
      email
    }
  }
})
</script>
